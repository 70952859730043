import React from 'react';
import {
  Divider,
  Header,
} from 'semantic-ui-react';

const Footer = () => (
    <Divider horizontal style={{ margin: '2em 0 4em' }}>
        <Header as='h5' textAlign='center' color='grey'>
            Created by <a href="https://www.torn.com/profiles.php?XID=2323221">Brainslug [2323221]</a>
        </Header>
    </Divider>
);

export default Footer;