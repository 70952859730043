import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  Menu,
  Header,
  Responsive,
  Segment,
  Visibility,
} from 'semantic-ui-react'
import { getWidth, MenuItem, ucfirst } from '../helpers';
import banner from '../assets/header.jpg';
import billboard from '../assets/billboard.png';


const DesktopMenu = ({ fixed, toggleLogin, loginActive=false }) => (
  <Menu
    inverted
    fixed={fixed ? 'top' : null}
    pointing={!fixed}
    secondary={!fixed}
    className='bs-menu'
    size='large'>
    <Container>
      <MenuItem slug='pricelist'>Pricelist</MenuItem>
      <MenuItem slug='artwork'>Artwork</MenuItem>
      <MenuItem slug='scripts'>Scripts</MenuItem>
      <Menu.Item position='right'>
        <Button 
          as='a' 
          onClick={toggleLogin} 
          inverted={!loginActive}>
          Log in
        </Button>
      </Menu.Item>
    </Container>
  </Menu>
);

const DesktopHeading = () => {
  const { pathname } = useLocation();
  const title = useMemo(() => {
    const slugParts = pathname.substr(1).split('-');
    return slugParts.map(part => ucfirst(part)).join(' ');
  }, [pathname]);
  return (
    <div className='bs-billboard' style={{ backgroundImage: `url(${billboard})` }}>
      <div className='bs-content'>
        <Header as='h1' content={title} />
      </div>
    </div>
  );
};

export default function DesktopContainer({ children }) {
  const [fixed, setFixed] = useState(false);
  return (
    <Responsive 
      getWidth={getWidth} 
      minWidth={Responsive.onlyTablet.minWidth}>
      <Visibility
        once={false}
        onBottomPassed={() => setFixed(true)}
        onBottomPassedReverse={() => setFixed(false)}>
        <Segment
          inverted
          vertical
          textAlign='center'
          className='desktop bs-header'
          style={{ backgroundImage: `url(${banner})` }}>
          <DesktopMenu fixed={fixed} />
          <DesktopHeading />
        </Segment>
      </Visibility>
      {children}
    </Responsive>
  );
}