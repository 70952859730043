import React from 'react';
import './maintenance.css';

const Maintenance = () => (
    <div id="maintenance">
    <article>
        <h1>Still under construction</h1>
        <div>
        <p>
            Sorry for the inconvenience
        </p>
        <p>&mdash; Brainslug</p>
        </div>
    </article>
    </div>
);

export default Maintenance;