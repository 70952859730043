import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { StateProvider } from './store';
import DesktopLayout from './layouts/Desktop';
import MobileLayout from './layouts/Mobile';
import Pricelist from './pages/Pricelist';
import Artwork from './pages/Artwork';
import Scripts from './pages/Scripts';
import './App.scss';
import Footer from './components/Footer';

const AppContainer = ({ children }) => (
  <Fragment>
    <DesktopLayout>
      {children}
    </DesktopLayout>
    <MobileLayout>
      {children}
    </MobileLayout>
    <Footer />
  </Fragment>
);

export default function App() {
  return (
    <Router>
      <StateProvider>
        <AppContainer>
          <Switch>
            <Route path="/pricelist">
              <Pricelist />
            </Route>
            <Route path="/artwork">
              <Artwork />
            </Route>
            <Route path="/scripts">
              <Scripts />
            </Route>
            <Route path="/">
              <Redirect to="/pricelist" />
            </Route>
          </Switch>
        </AppContainer>
      </StateProvider>
    </Router>
  );
}
