import React, { Fragment } from 'react'
import {
  Container,
  Grid,
  Header,
  Image,
  Segment,
  Divider,
} from 'semantic-ui-react'

// february
import dca_brainslug from '../assets/dca_brainslug.jpg';
import dc_brainslug from '../assets/dc_brainslug.jpg';
import dca_bob from '../assets/dca_bob.png';
import dc_bob from '../assets/dc_bob.png';
import dca_harry from '../assets/dca_harryaxewound.png';

// march 2020
import flock_shadow from '../assets/varsity_flock_shadow.png';
import flock_sheep from '../assets/varsity_flock_sheep.png';
import sleuth_father from '../assets/varsity_sleuth_father.png';
import vixens from '../assets/varsity_vixens.jpg';

// april 2020
import friend_pryan from '../assets/friend_pryan187.gif';
import olympus from '../assets/olympus.gif';

const MonthContainer = ({ month, year, children }) => (
  <Fragment>
      <Divider as='h4' className='header' horizontal style={{ margin: 0, textTransform: 'uppercase' }}>
          {`[ ${month} ${year}]`}
      </Divider>
      <Segment style={{ padding: '2em 0' , maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }} basic>
          {children}
      </Segment>
  </Fragment>
);

const BannerList = ({ children }) => (
  <Fragment>
      {children.map((img, i) => (
          <Image src={img} style={{ width: '100%', marginBottom: '1em' }} key={i} />
      ))}
  </Fragment>
);

const ProfileGrid = ({columns=4, children }) => (
  <Grid columns={columns} centered>
    {children.map((image, i) => (
      <Grid.Column key={i}>
          <Image src={image} style={{ width: '100%' }} />
      </Grid.Column>
    ))}
</Grid>
)

const Artwork = () => (
  <Fragment>
    <Segment style={{ padding: '4em 0em' }} vertical basic>
      <Container text textAlign='center'>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Brainslug's Gallery
        </Header>
        <p style={{ fontSize: '1.33em' }}>
        The Spiderians, though weak and woman-like on the battlefield, 
        are masters of the textile arts. Taste like king crab, by the way. 
        Crazy bugs actually wove this tapestry of my heroic conquest while I was still killing them. 
        </p>
      </Container>
    </Segment>
    <MonthContainer year={2020} month='April'>
        <BannerList>{[ friend_pryan, olympus ]}</BannerList>
    </MonthContainer>
    <MonthContainer year={2020} month='March'>
        <BannerList>{[ flock_sheep, flock_shadow, sleuth_father, vixens ]}</BannerList>
    </MonthContainer>
    <MonthContainer year={2020} month='February'>
        <ProfileGrid columns={3}>{[dca_brainslug, dc_brainslug, dca_harry, dca_bob, dc_bob]}</ProfileGrid>
    </MonthContainer>
  </Fragment>
)

export default Artwork;