import React, { Fragment, useState, useMemo, useEffect } from 'react'
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
  Card,
  Table,
  Input,
  Responsive,
} from 'semantic-ui-react';
import { pricify, range } from '../helpers';
import moment from 'moment';
// import { items } from '../assets/items.json';

// const sellingItems = (function (_items) {
//   let result = {};
//   Object.keys(_items).forEach(id => {
//     const { name, type, sell } = _items[id];
//     if (sell) {
//       if (!result[type]) result[type] = [];
//       result[type].push({ name, type, id });
//     }
//   });
//   return Object.keys(result)
//     .map(group => ({ title: group, items: result[group] }));
// })(items);

const PriceCard = ({ title, children }) => (
  <Card fluid>
    <Card.Content>
      <Card.Header textAlign='center'>
        {title}
      </Card.Header>
    </Card.Content>
    <Card.Content>
      <Table basic='very' celled stackable collapsing style={{ width:'100%' }}>
        <Table.Body>
          {children.map(({ id, name, price }) => (
            <Table.Row key={id}>
              <Table.Cell>
                <Header as='h4' image>
                  <Image src={`https://files.brainslug.nl/torn/items/${id}/small.png`} rounded/>
                  <Header.Content>
                    {name}
                    <Header.Subheader>${pricify(price * 0.92)}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Card.Content>
  </Card>
);

const ChunkedList = ({ columns, items }) => {
  const rows = Math.ceil(items.length / columns),
    _width = 16 / columns;
  const columnList = useMemo(() => range(columns).map(column => {
    const rowList = range(rows).map(row => {
      const index = (row % 2 === 0) ? row * columns + column : (row + 1) * columns - column - 1;
      if (!items[index]) 
        return undefined;
      const { title, items: _items } = items[index];
      return (
        <PriceCard title={title} key={title}>
          {_items}
        </PriceCard>
      );
    }).filter(x => !!x);
    if (rowList.length === 0)
      return undefined;
    return (
      <Grid.Column width={_width} key={column}>
        {rowList}
      </Grid.Column>
    )
  }).filter(x => !!x), [columns, items, _width, rows]);
  return (
    <Grid container centered>
      {columnList}
    </Grid>
  );
}

const SearchInput = props => <Input {...props} fluid size='big' icon='search' placeholder='Search...' />;

const Pricelist = () => {
  const [query, setQuery] = useState('');
  const [updated, setUpdated] = useState('never');
  const [groupedItems, setItems] = useState([]);
  const filteredItems = useMemo(() => {
    const _query = query.toLowerCase().trim();
    return (groupedItems || []).map(({ items, title }) => {// filter items by query
        let groupItems = items.slice();
        if (_query.length > 1) {
          groupItems = items.slice().map((item, i) => {
            const { name } = item,
              start = name.toLowerCase().indexOf(_query),
              end = start + _query.length;
            if (start >= 0) {
              return {...item, name: (
                <Fragment>
                  {name.substring(0, start)}
                    <strong style={{ color: 'red' }}>
                      {name.substr(start, _query.length)}
                    </strong>
                  {name.substring(end)}
                </Fragment>
              )};
            }
            return undefined;
          }).filter(x => !!x);
        }
        return { title, items: groupItems };
      })
      .filter(({ items }) => items.length > 0)
      .sort((a, b) => {// reverse sort items
        if (a.items.length < b.items.length) return 1;
        if (a.items.length > b.items.length) return -1;
        return 0;
      });
  }, [query, groupedItems]);
  useEffect(() => {
    fetch('/pricelist.json')
      .then(r => r.json())
      .then(({ items, updated }) => {
        let result = {};
        items.forEach(({ type, ...props }) => {
          if (!result[type]) result[type] = [];
          result[type].push({ type, ...props });
        });
        setUpdated(moment.unix(updated).fromNow());
        return Object.keys(result)
          .map(group => ({ title: group, items: result[group] }));
      }).then(setItems);
  }, []);
  return (
    <Fragment>
      <Segment style={{ padding: '4em 0 2em 0' }} vertical basic>
        <Container text textAlign='center'>
          <Header as='h3' style={{ fontSize: '2em' }}>
            Brainslug's Market
          </Header>
          <p style={{ fontSize: '1.33em' }}>
            It can be seen by flying to the edge of the universe, 
            where one will encounter one's counterparts mirroring one's actions. 
          </p>
          <Responsive as={SearchInput} {...Responsive.onlyMobile} style={{ margin: '0 .5em' }} value={query} onChange={e => setQuery(e.target.value)} />
          <Responsive as={SearchInput}  {...Responsive.onlyTablet} style={{ margin: '0 2em' }} value={query} onChange={e => setQuery(e.target.value)} />
          <Responsive as={SearchInput} {...Responsive.onlyComputer} style={{ margin: '0 6em' }} value={query} onChange={e => setQuery(e.target.value)} />
        </Container>
      </Segment>
      <Divider
        as='h4'
        className='header'
        horizontal
        style={{ margin: 0, textTransform: 'uppercase' }}>
        Items updated {updated}
      </Divider>
      <Segment style={{ padding: '4em 0em' }} vertical>
        <Responsive as={ChunkedList} {...Responsive.onlyMobile} columns={1} items={filteredItems} />
        <Responsive as={ChunkedList} {...Responsive.onlyTablet} columns={2} items={filteredItems} />
        <Responsive as={ChunkedList} {...Responsive.onlyComputer} columns={4} items={filteredItems} />
      </Segment>
    </Fragment>
  );
};

export default Pricelist;