import React, { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Container,
  Menu,
  Header,
  Responsive,
  Segment,
  Sidebar,
  Icon,
  Form,
  Divider, 
} from 'semantic-ui-react';
import { getWidth, MenuItem, ucfirst } from '../helpers';
import banner from '../assets/header.jpg';

export default function MobileContainer({ children }) {
  const { pathname } = useLocation();
  const title = useMemo(() => {
    const slugParts = pathname.substr(1).split('-');
    return slugParts.map(part => ucfirst(part)).join(' ');
  }, [pathname]);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  return (
    <Responsive
      as={Sidebar.Pushable}
      getWidth={getWidth}
      maxWidth={Responsive.onlyMobile.maxWidth}>
      <Sidebar
        as={Menu}
        animation='push'
        inverted
        onHide={() => setSidebarOpened(false)}
        vertical
        visible={sidebarOpened}>
        <MenuItem slug='pricelist'>Pricelist</MenuItem>
        <MenuItem slug='artwork'>Artwork</MenuItem>
        <MenuItem slug='scripts'>Scripts</MenuItem>
        <Divider hidden />
        <Form size='large'>
          <Segment stacked inverted>
            <Header as='h4'>
              Log-in
            </Header>
            <Form.Input 
              fluid 
              inverted
              transparent
              icon='user' 
              iconPosition='left' 
              placeholder='E-mail address' />
            <Form.Input
              fluid
              inverted
              transparent
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'/>
            <Button as='a' inverted fluid>
              Log in
            </Button>
          </Segment>
        </Form>
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            className='mobile bs-header'
            style={{ backgroundImage: `url(${banner})` }}>
            <Container>
              <Menu 
                inverted 
                pointing 
                secondary 
                size='large' 
                className='bs-menu'>
                <Menu.Item onClick={() => setSidebarOpened(true)}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <strong>{title}</strong>
                </Menu.Item>
              </Menu>
            </Container>
          </Segment>
          {children}
      </Sidebar.Pusher>
    </Responsive>
  );
}