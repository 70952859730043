import React from 'react';
import { 
  Link,
  useLocation,
 } from "react-router-dom";
import {
  Responsive,
  Menu,
} from 'semantic-ui-react'

export const getWidth = () => {
  const isSSR = typeof window === 'undefined'
  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
};

export const MenuItem = ({ slug, to=false, ...props }) => {
  const location = useLocation();
  const pathname = to || '/' + slug;
  return (
    <Menu.Item 
      as={Link} 
      to={pathname}
      className='bs-item'
      active={location.pathname === pathname}
      {...props}
    />
  );
};

export const ucfirst = str => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
export const pricify = price => {
  let p, i, c;
  if (price > 1000000) {
    p = '' + Math.round(price / 10000) * 10000;
  } else if (price > 100000) {
    p = '' + Math.round(price / 1000) * 1000;
  } else if (price > 1000) {
    p = '' + Math.round(price / 100) * 100;
  } else {
    p = '' + Math.round(price / 10) * 10;
  }
  let result = [];
  for (i = p.length - 3, c = 0; i > 0; i -= 3, c += 3) {
    result.unshift(p.substr(i, 3));
  }
  let x = p.length - result.length * 3;
  if (x > 0) {
    result.unshift(p.substr(0, x));
  }
  return result.join(',');
};

export const range = to => {
  let r = [], i;
  for (i = 0; i < to; i++) {
    r.push(i);
  }
  return r;
};