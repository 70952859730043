import React, {createContext, useReducer, useContext} from 'react';
import { reducer, initialState } from './state';

export const store = createContext(initialState);
const { Provider } = store;

export const StateProvider = ({ children }) => {
    return (
        <Provider value={useReducer(reducer, initialState)}>
            {children}
        </Provider>
    );
};

export const useStore = () => {
    // eslint-disable-next-line no-unused-vars
    const [data, _] = useContext(store);
    return data;
}

export const useDispatch = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, dispatch] = useContext(store);
    return (action, data) => dispatch({ action, data });
};